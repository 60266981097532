import { FC, Fragment, useCallback, useContext } from 'react'
import { Button, Icon, Text, Image } from '@/client/components'
import { CartContext } from '@/client/context/cart'
import * as S from './styles'
import {
  getOptionValue,
  getOptionValueForShortSummary,
  getOptionsField,
  isExcludingField,
  getCorrectDimensionType
} from '../helper'
import { Steps, SummaryChildProps } from '../types'
import { useMutatePrices } from '@/client/hooks'
import { useRouter } from 'next/router'

export const Summary: FC<SummaryChildProps> = ({
  calculatorFields,
  optionFields,
  setStep,
  texts,
  randomText,
  isArtworkService,
  production_time
}) => {
  const { locale } = useRouter()
  const { updateSelection, selection } = useContext(CartContext)
  const [order, ...restOptionFields] = optionFields
  const { isLoading, mutate } = useMutatePrices(locale)

  const keysToExclude = [
    'paper',
    'quantity',
    'dimension',
    'diameter',
    'tpy_cta',
    'tpy_type',
    'tpy_layout',
    'number_of_sku',
    'tpy_lang'
  ]

  const selectedOption = (key) => {
    const name = getOptionsField(calculatorFields, key).find((option) => option.id === selection[key])?.name
    return name
  }

  const selectDeliveryType = useCallback(
    (type) => {
      const newSelection = { ...selection, express_production: type }
      updateSelection(newSelection)
      mutate(newSelection)
    },
    [updateSelection, selection, mutate]
  )

  return (
    <>
      <S.Summary>
        <S.SummaryHeader>
          {isArtworkService ? (
            <>
              <Text size='lg' family='heading' weight='bold' margin='0'>
                {randomText?.project_name}
              </Text>
            </>
          ) : (
            <>
              <Text size='lg' family='heading' weight='bold' margin='0'>
                {order.displayName}
              </Text>
            </>
          )}

          <Button size='small' kind='weak' isOutlined onClick={() => setStep(Steps.OPTIONS)}>
            <Icon name='edit' />
            {texts.edit_button}
          </Button>
        </S.SummaryHeader>
        <S.SummaryBody>
          {isArtworkService ? (
            <>
              <Text size='sm' color='weak' margin='0 0 0.125rem'>
                {randomText?.project_name}
              </Text>
              <Text weight='medium' margin='0'>
                {selection.artwork_service_project_name}
              </Text>
            </>
          ) : (
            <>
              {order.options.map(({ name, key }) => (
                <Fragment key={key}>
                  <Text size='sm' color='weak' margin='0 0 0.125rem'>
                    {name}
                  </Text>
                  <Text weight='medium' margin='0'>
                    {selection[key]}
                  </Text>
                </Fragment>
              ))}
            </>
          )}
        </S.SummaryBody>
      </S.Summary>

      <S.Summary>
        <S.SummaryHeader>
          <Text size='lg' family='heading' weight='bold' margin='0'>
            {texts.tag}
          </Text>
          <Button size='small' kind='weak' isOutlined onClick={() => setStep(Steps.SIZE_AND_FORMAT)}>
            <Icon name='edit' />
            {texts.edit_button}
          </Button>
        </S.SummaryHeader>

        <>
          <S.MainSummary>
            <S.ShortSummary>
              <S.PositionSvg>
                <Image src={`/icons/rolls-icon.svg`} width='319' height='490' alt='rolls' />
                <S.PositionTextInSvg>
                  <Text size='sm' color='normal' margin='0 0 3rem 0' weight='bold'>
                    {randomText?.quick_summary}
                  </Text>
                  <>
                    <>
                      <>
                        <S.IconAligner>
                          <Icon name='dimension' size='s5' />
                          <Text size='sm' color='weak' margin='0 0 1rem 1rem'>
                            {
                              calculatorFields.find(
                                (field) => field.key === getCorrectDimensionType(selection, calculatorFields)
                              )?.displayName
                            }
                          </Text>
                        </S.IconAligner>
                        <Text
                          size='xlg'
                          color='primary'
                          margin='0 0 1rem 0'
                          weight='bold'
                          style={{ borderBottom: '2px dashed #EAEDF0' }}
                        >
                          {getOptionValueForShortSummary(
                            selection,
                            getCorrectDimensionType(selection, calculatorFields)
                          )}
                        </Text>
                      </>

                      <>
                        <S.IconAligner>
                          <Icon name='quantity-icon' size='s5' />
                          <Text size='sm' color='weak' margin='0 0 1rem 1rem'>
                            {calculatorFields.find((field) => field.key === 'quantity')?.displayName}
                          </Text>
                        </S.IconAligner>
                        <Text
                          size='xlg'
                          color='primary'
                          margin='0 0 1rem 0'
                          weight='bold'
                          style={{ borderBottom: '2px dashed #EAEDF0' }}
                        >
                          {getOptionValueForShortSummary(selection, 'quantity')}
                        </Text>
                      </>
                      <>
                        <S.IconAligner>
                          <Icon name='material-icon' size='s5' />
                          <Text size='sm' color='weak' margin='0 0 1rem 1rem'>
                            {calculatorFields.find((field) => field.key === 'paper')?.displayName}
                          </Text>
                        </S.IconAligner>
                        <Text
                          size='xs'
                          color='primary'
                          margin='0 0 1rem 0'
                          weight='bold'
                          style={{ borderBottom: '2px dashed #EAEDF0' }}
                        >
                          {selectedOption('paper')}
                        </Text>
                      </>
                    </>
                  </>
                </S.PositionTextInSvg>
              </S.PositionSvg>
            </S.ShortSummary>
            <S.SummaryBody>
              <S.DetailedSummary>
                {calculatorFields.map(
                  ({ key, displayName, options, exclude }) =>
                    isExcludingField(exclude, selection) &&
                    !keysToExclude.includes(key) && (
                      <Fragment key={key}>
                        <div style={{ display: 'inline-block' }}>
                          <Text size='sm' color='weak' margin='0 0 0.125rem'>
                            {displayName}
                          </Text>
                          <Text weight='medium' margin='0 0 1.5rem'>
                            {getOptionValue(options, selection, key)}
                          </Text>
                        </div>
                        <br />
                      </Fragment>
                    )
                )}
                <>
                  <div>
                    <Text size='sm' color='weak' margin='0 0 0.125rem'>
                      {randomText?.notes}
                    </Text>
                    <Text weight='medium' margin='0 0 1.5rem'>
                      {selection.artwork_service_description ? selection.artwork_service_description : '-'}
                    </Text>
                  </div>
                  <br />
                </>
              </S.DetailedSummary>
            </S.SummaryBody>
          </S.MainSummary>
        </>
      </S.Summary>

      <>
        <S.Summary>
          <S.SummaryHeader>
            <Text size='lg' family='heading' weight='bold' margin='0'>
              {texts.production_details.heading}
            </Text>
          </S.SummaryHeader>

          <S.Production>
            <S.ProductionType
              role='button'
              tabIndex={0}
              selected={selection?.express_production === false}
              onClick={() => selectDeliveryType(false)}
            >
              <Icon name='proof-production-time' size='s8' className='standard-icon' />
              <div style={{ padding: '1rem' }}>
                <Text
                  size='md'
                  weight={selection?.express_production === false ? 'bold' : 'normal'}
                  margin='0'
                  lineHeight='1.2'
                >
                  <span dangerouslySetInnerHTML={{ __html: texts.production_details.standard_button }} />*
                </Text>
                <Text size='sm' margin='0' color='primary' weight='bold'>
                  {production_time.dateStandard}
                </Text>
              </div>
              <Image src={'/img/standard-arrow.png'} height='25' width='15' alt='standard-arrow' />
            </S.ProductionType>
            <S.ProductionType
              role='button'
              tabIndex={0}
              selected={selection?.express_production === true}
              onClick={() => selectDeliveryType(true)}
            >
              <Icon name='express-production' size='s9' />
              <div style={{ padding: '1rem' }}>
                <Text
                  size='md'
                  weight={selection?.express_production === true ? 'bold' : 'normal'}
                  margin='0'
                  lineHeight='1.2'
                >
                  <span dangerouslySetInnerHTML={{ __html: texts.production_details.express_button }} />*
                </Text>
                <Text size='sm' margin='0' color='warning-light' weight='bold'>
                  {production_time.dateExpress}
                </Text>
              </div>

              <Image src={'/img/express-arrow.png'} height='25' width='25' alt='express-arrow' />
            </S.ProductionType>
          </S.Production>
          <Text size='xs' margin='0' lineHeight='1.4'>
            {texts.production_details.description}
          </Text>
        </S.Summary>
      </>

      <>
        {!isArtworkService && (
          <S.Summary>
            <S.SummaryHeader>
              <Text size='lg' family='heading' weight='bold' margin='0'>
                {texts.options}
              </Text>
              <Button size='small' kind='weak' isOutlined onClick={() => setStep(Steps.OPTIONS)}>
                <Icon name='edit' />
                {texts.edit_button}
              </Button>
            </S.SummaryHeader>

            <S.SummaryBody>
              {restOptionFields.map(({ key, displayName, options }) => (
                <Fragment key={key}>
                  <Text size='sm' color='weak' margin='0 0 0.125rem'>
                    {displayName}
                  </Text>
                  <Text weight='medium' margin='0 0 1.5rem'>
                    {getOptionValue(options, selection, key)}
                  </Text>
                </Fragment>
              ))}
            </S.SummaryBody>
          </S.Summary>
        )}
      </>
    </>
  )
}
